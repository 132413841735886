import React from "react"


const NotFoundPage = () => (
  <div style={{
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}>
    <h1 style={{ fontSize: "52px" }}>404: Not Found</h1>
  </div>
)

export default NotFoundPage
